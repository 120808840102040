<template>
<b-container class="bull-bet-actions" fluid :class="[{'betting-area-disable': isBetClosed || initBetting}]">
    <b-row>
        <b-col cols="6"></b-col>
        <b-col cols='6'>
            <span class="chip" v-for="(chip, idx) in chips" :key="idx" :class="{ 'chip-selected': game.chipSelected == chip.chipamt,  'loader-pc': game.chipSelected == chip.chipamt}" @click="selectChipValue(chip.chipamt)">
                {{ showChipDispay(chip) }}
            </span>
        </b-col>
    </b-row>
    <b-row>
        <b-col cols="6"></b-col>
        <b-col cols="6" v-bind:class="[isMobile() ? 'bet-buttons-bull' : 'bet-buttons']">
            <b-row>
                <b-col cols='4'>
                    <b-button variant="primary" size="sm" style="margin-right:5px" @click="clearBet()" class='bet-buttons clear-button bull-buttons'>{{ $t('game.clearBet') }}</b-button>
                </b-col>
                <b-col cols='4'>
                    <b-button variant="danger" size="sm" @click="cancelBet()" class='bet-buttons cancel-button bull-buttons'>{{ $t('game.cancelBet') }}</b-button>
                </b-col>
                <b-col cols='4'>
                    <b-button variant="success" size="sm" style="margin-left:5px" @click="sendBet()" class='bet-buttons send-button bull-buttons'>{{ $t('game.sendBet') }}</b-button>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</b-container>
</template>

<script>
export default {
    data() {
        return {
            game: {
                chipSelected: 0
            },
            initBetting: true
        }
    },
    props: {
        bet: {
            required: true,
            type: Array
        },
        successfulBet: {
            required: true,
            type: Object
        }
    },
    computed: {
        limit() {
            return this.$store.getters['gameModule/getCurrentLevelLimit']
        },
        currentRoom() {
            return this.$store.state.gameModule.currentRoom
        },
        isBetClosed() {
            return this.currentRoom.state != 1
        },
        chips() {
            return this.$store.state.gameModule.betChips;
        },
        isDisabled() {
            return (this.isBetClosed || this.initBetting)
        },
        cancelTime() {
            return parseInt(this.$store.state.gameModule.settings.cancelTime)
		},
		prevBetTime: {
            required: true, type: Number
		},
        cancelType() {
            return this.$store.state.gameModule.settings.cancelType
		},
		currentTimer() {
			return this.$store.state.gameModule.currentRoom.timer
		}
    },
    methods: {
        sendBet() {

            /* if (this.isBetClosed || this.initBetting) {
                 this.$emit('setErrorMessage', 'BET_CLOSED');
                 return false;
             } */

            if (this.bet.length == 0) {
                this.$emit('setErrorMessage', 'NO_BET');
                return false;
            }

            let totalBetAmount = 0,
                bettings = [],
                currentbettings = [],
                currentBetObj;

            bettings = this.bet;
            currentbettings = [];

            bettings.forEach(item => {

                currentBetObj = {
                    betpos: item.betpos,
                    betamt: item.betamt
                };
                currentbettings.push(currentBetObj);

                totalBetAmount += item.betamt;
            });

            let betData = {
                gameCode: this.$store.state.gameModule.game,
                roomno: this.currentRoom.roomno,
                roomorder: this.currentRoom.roomorder,
                level: this.$store.state.gameModule.currentLevel,
                currency: this.$store.state.gameModule.member.currency,
                bettings,
                currentbettings,
                totalBetAmount
            };

            // Dispatch Submit Betting
            //console.log(betData)
            this.$store.dispatch('betModule/betSubmit', betData);
            this.clearBet();
        },
        selectChipValue(selectedValue) {
            /*if (this.game.chipSelected != selectedValue) {
            	this.game.chipSelected = selectedValue;
            } else {
            	this.game.chipSelected = 0;
            }*/
            //if (!this.isBetClosed) {
            this.game.chipSelected = selectedValue;
            this.$store.commit('gameModule/SET_SELECTEDCHIP', selectedValue);
            //}
        },
        showChipDispay(chip) {
            if (this.game.chipSelected == 0) {
                this.game.chipSelected = chip.chipamt;
                this.$store.commit('gameModule/SET_SELECTEDCHIP', this.game.chipSelected);
            }
            var chipDisplay = '';
            var res;
            if (chip.currency == 'KRW') {

                if (chip.chipamt >= 10000) {
                    res = ~~(chip.chipamt / 10000);
                    chipDisplay += res + this.$t('game.chipCurrency.KRW10000');
                }
                if ((res = chip.chipamt % 10000) > 0) {
                    chipDisplay += ~~(res / 1000) + this.$t('game.chipCurrency.KRW1000');
                }
            } else {
                chipDisplay = ~~(chip.chipamt / 1000) + this.$t('game.chipCurrency.USD1000');
            }
            return chipDisplay;
        },
        clearBet() {
            this.$emit('initBet');
        },
        cancelBet() {
            this.clearBet();
			
			if (this.prevBetTime != 0) {
				if (this.cancelType == 'FROM_TIMER') {
					if (this.cancelTime > this.currentTimer) {
						this.$emit('setErrorMessage', 'CANCEL_OVERTIME');
					} else {
						this.$store.dispatch('betModule/betCancel');
					}
				} else if (this.cancelType == 'FROM_BET') {
					var secondBetweenTwoDate = Math.abs((new Date().getTime() - this.prevBetTime) / 1000);
					if (secondBetweenTwoDate > this.cancelTime) {
						this.$emit('setErrorMessage', 'CANCEL_OVERTIME');
					} else {
						this.$store.dispatch('betModule/betCancel');
					}
				}
			}
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
    },
    watch: {
        isBetClosed: {
            handler() {
                if (this.$store.state.gameModule.currentRoom.state != 1 && this.initBetting) {
                    this.initBetting = false;
                }
            },
            deep: true
        }
    }
}
</script>

<style>
.bull-bet-actions {
    position: absolute;
    bottom: -75%;
    text-align: center;
}

.img-position {
    width: 80px;
    height: 80px;
    margin-top: -6px;
    margin-right: 8px;
}

.chips {
    position: relative;
}

.chip-text {
    position: absolute;
    top: -4px;
    left: -5px;
    font-size: 15px;
    color: #ffffff;
    text-align: center;
    width: 100%;
    height: 100%;
    font-weight: 900;
    -webkit-text-stroke: 1px black;
}

.chips-active {
    color: #c29e00;
}
</style>
