<!--=========================================================================================
  File Name: ShoeArea.vue
  Description: Bull Shoe Component
==========================================================================================-->
<template>
<b-container fluid style="margin-left: 0px;margin-right: 0px;padding-left: 0px;padding-right: 0px;">
    <result-road :shoeResults="currentShoe" :bossCard="currentBossCard" />
</b-container>
</template>

<script>
import ResultRoad from '@/components/bull/roads/mobile/ResultRoad'
export default {
    name: 'BullShoeArea',
    components: {
        ResultRoad
    },
    computed: {
        currentRoom() {
            return this.$store.state.gameModule.currentRoom
        },
        currentShoe() {
            let shoeData = '';
            if (this.currentRoom.shoe) {
                shoeData = (this.currentRoom.shoe).slice(0, -1)
                //console.log(shoeData);
            }
            return shoeData;            
        },
        currentBossCard() {
            let cardData = '';
            if (this.currentRoom.state != '5' && this.currentRoom.card && this.currentRoom.card.boss && this.currentRoom.card.boss.length > 0) {
                cardData = (this.currentRoom.card.boss[0]).substring(1);
            }
            return cardData;
        }
    }
}
</script>

<style>

</style>
