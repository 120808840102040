<!--=========================================================================================
  File Name: GameScreen.vue
  Description: Mobile Bull Game page
==========================================================================================-->
<template>
<b-container fluid style="padding-left:0px !important;padding-right:0px !important;">
    <video-player :initBetting='initBetting' @setCurrentTimer="setCurrentTimer" />
    <game-results />
    <betting-area :currentTimer="currentTimer" />
    <shoe-area />
</b-container>
</template>

<script>
import VideoPlayer from '@/components/bull/game/VideoPlayer'
import GameResults from '@/components/bull/game/mobile/GameResults'
import BettingArea from '@/components/bull/game/mobile/BettingArea'
import ShoeArea from '@/components/bull/game/mobile/ShoeArea'
export default {
    name: "GameScreen",
    components: {
        VideoPlayer,
        GameResults,
        BettingArea,
        ShoeArea
    },
    data() {
        return {
            timer: 60,
            initBetting: false,
            currentTimer: 0,
        }
    },
    created() {
        this.$store.dispatch('gameModule/checkIfLoggedIn')
    },
    computed: {
        currentRoom() {
            return this.$store.state.gameModule.currentRoom
        },
    },
    methods: {
        setCurrentTimer(val) {
            this.currentTimer = val;
		},
    }
}
</script>

<style>
@import '../../../assets/scss/bull-main-mobile.scss';
@import '../../../assets/scss/mobile/spinning_bet_bg_pc.scss';
</style>
